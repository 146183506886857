
import React from "react";
import { MdOutlineKeyboardBackspace } from 'react-icons/md'





interface PropData {
  Title: string
  subTitle?: any
}

const PageTitle = (props: PropData) => {
  const goBack = () => {
    window.history.back();
  };
  return (
    <>
      <div className="back-arrow">
        <div className="d-flex align-items-center gap-2">
          {(
            <a href="javascript:void(0)" className="left-arrow mr-3 text-brand">
              <MdOutlineKeyboardBackspace size={24} onClick={goBack} />
            </a>
          )}
          <div className="page-title">
            {
              !props.subTitle ?
                `${props.Title}` :
                `${props.Title}: ${props.subTitle}`
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default PageTitle;




