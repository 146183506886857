import avtar from "../../assets/images/default-avatar.jpg";
const initialState = {
  profileImage: localStorage.getItem("pI")
    ?? avtar,
  progressBar: 0,
  role: null,
  id: undefined,
  name: "",
  email: "",
  clientId: undefined,
  tenantId: undefined,
  iA: undefined,
  oA: undefined,
  aA: undefined,
  tI: undefined,
  tfa: false,
  sUrl:undefined,
  oS:undefined,
  iAuto:false
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_PROFILE_IMAGE':
      return {
        ...state,
        profileImage: action.payload
      };
    case 'UPDATE_PROGRESS_BAR':
      return {
        ...state,
        progressBar: action.payload
      };
    case 'USER_ROLE':
      return {
        ...state,
        role: action.payload,
      };
    case 'USER_ID':
      return {
        ...state,
        id: action.payload,
      };
    case 'USER_NAME':
      return {
        ...state,
        name: action.payload,
      };
    case 'USER_EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    case 'CLIENT_ID':
      return {
        ...state,
        clientId: action.payload,
      };
    case 'TENANT_ID':
      return {
        ...state,
        tenantId: action.payload,
      };
    case 'UAT':
      return {
        ...state,
        uat: action.payload,
      };
    case 'INBOUND_ARCHIVING':
      return {
        ...state,
        iA: action.payload,
      };
    case 'OUTBOUND_ARCHIVING':
      return {
        ...state,
        oA: action.payload,
      };
    case 'ACCESS_TOKEN':
      return {
        ...state,
        aT: action.payload,
      };
    case 'TWO_FA_ENABLED':
      return {
        ...state,
        tfa: action.payload,
      };
    case 'AI_MODEL':
      return {
        ...state,
        aiM: action.payload,
      };
    case 'AI_CHATBOT_ENABLED':
      return {
        ...state,
        aCE: action.payload,
      };
    case 'VAULT_ENABLED':
      return {
        ...state,
        vCE: action.payload,
      };
    case 'SALESFORCE_URL':
      return {
        ...state,
        sUrl: action.payload,
      };
    case 'OTHER_SERVICE':
      return {
        ...state,
        oS: action.payload,
        };
    case 'IMESSAGE_AUTOMATION':
      return {
         ...state,
        iAuto: action.payload,
        };
    case 'LOGOUT':
      return initialState;
         
    default:
      return state;
  }
};

export default profileReducer;




